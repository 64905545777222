<template>
  <div class="mflswitch" id="mflswitch">
    <!-- 标题部分 -->
    <div class="title-hang">
      <div class="fl">
        <dv-decoration-1 style="width: 14vw; height: 3vh; margin-top: 1vh" />
      </div>
      <div class="zhuangshizb fl"></div>
      <div class="zhuangshizj fl">{{ projectData.project }}_二次风</div>
      <div class="zhuangshiyb fl">
        <div class="fl">
          <dv-decoration-1
            style="width: 14vw; height: 3vh; margin-top: 0vh; margin-left: 16vw"
          />
        </div>
      </div>
    </div>
    <!-- 主体部分的上边 -->
    <div class="margin-top">
      <div class="margin-top-row fl">
        <div class="main-lable">E层负荷折线修正</div>
        <div class="top-row1">
          <div class="top-row1-col1 fl">序号</div>
          <div class="top-row1-col2 fl">EMSPJ_IN</div>
          <div class="top-row1-col3 fl">EMSPJ_AV</div>
        </div>
        <div class="top-row2">
          <div class="top-row1-col1 fl">1</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.X__ll__1__rr__,
                    'EMSPJ_IN1',
                     'X__ll__1__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.X__ll__1__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.Y__ll__1__rr__,
                    'EMSPJ_AV1',
                     'Y__ll__1__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.Y__ll__1__rr__ }}</div>
        </div>
        <div class="top-row2">
          <div class="top-row1-col1 fl">2</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.X__ll__2__rr__,
                    'EMSPJ_IN2',
                     'X__ll__2__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.X__ll__2__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.Y__ll__2__rr__,
                    'EMSPJ_AV2',
                     'Y__ll__2__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.Y__ll__2__rr__ }}</div>
        </div>
                <div class="top-row2">
          <div class="top-row1-col1 fl">3</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.X__ll__3__rr__,
                    'EMSPJ_IN3',
                     'X__ll__3__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.X__ll__3__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.Y__ll__3__rr__,
                    'EMSPJ_AV3',
                     'Y__ll__3__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.Y__ll__3__rr__ }}</div>
        </div>
                <div class="top-row2">
          <div class="top-row1-col1 fl">4</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.X__ll__4__rr__,
                    'EMSPJ_IN4',
                     'X__ll__4__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.X__ll__4__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.Y__ll__4__rr__,
                    'EMSPJ_AV4',
                     'Y__ll__4__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.Y__ll__4__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">5</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.X__ll__5__rr__,
                    'EMSPJ_IN5',
                     'X__ll__5__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.X__ll__5__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.Y__ll__5__rr__,
                    'EMSPJ_AV5',
                     'Y__ll__5__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.Y__ll__5__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">6</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.X__ll__6__rr__,
                    'EMSPJ_IN6',
                     'X__ll__6__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.X__ll__6__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.Y__ll__6__rr__,
                    'EMSPJ_AV6',
                     'Y__ll__6__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.Y__ll__6__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">7</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.X__ll__7__rr__,
                    'EMSPJ_IN7',
                     'X__ll__7__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.X__ll__7__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.Y__ll__7__rr__,
                    'EMSPJ_AV7',
                     'Y__ll__7__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.Y__ll__7__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">8</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.X__ll__8__rr__,
                    'EMSPJ_IN8',
                     'X__ll__8__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.X__ll__8__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.Y__ll__8__rr__,
                    'EMSPJ_AV8',
                     'Y__ll__8__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.Y__ll__8__rr__ }}</div>
        </div>
                                <div class="top-row2">
          <div class="top-row1-col1 fl">9</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.X__ll__9__rr__,
                    'EMSPJ_IN9',
                     'X__ll__9__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.X__ll__9__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.Y__ll__9__rr__,
                    'EMSPJ_AV9',
                     'Y__ll__9__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.Y__ll__9__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">10</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.X__ll__10__rr__,
                    'EMSPJ_IN10',
                     'X__ll__10__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.X__ll__10__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.Y__ll__10__rr__,
                    'EMSPJ_AV10',
                     'Y__ll__10__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.Y__ll__10__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">11</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.X__ll__11__rr__,
                    'EMSPJ_IN11',
                     'X__ll__11__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.X__ll__11__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFEK.Y__ll__11__rr__,
                    'EMSPJ_AV11',
                     'Y__ll__11__rr__',
                    'MKG__p__ECFEK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFEK.Y__ll__11__rr__ }}</div>
        </div>

        <div class="top-row3">
          <div class="fl top-row3-col1">IN=</div>
          <div class="fl"
          @click="toCompon(2, 'ZQF_SP', 'IOG', 'ZQF_SP_BM','主汽流量设定值')"   
           @dblclick="Cclick(infoList.IOG.ZQF_SP,'ZQF_SP', 'IOG', 'ZQF_SP_BM')">{{infoList.IOG.ZQF_SP}}
          </div>
          <div class="fl top-row3-col1">AV=</div>
          <div class="fl"
            @click="toCompon(2, 'AV', 'MKG__p__ECFEK', 'ECFEK_AV_BM','主汽流量折线修正系数输出')"   
           @dblclick="Cclick(infoList.MKG__p__ECFEK.AV,'AV', 'IOG', 'ECFEK_AV_BM')">{{infoList.MKG__p__ECFEK.AV}}</div>
        </div>
      </div>
      <div class="margin-top-row fl">
        <div class="main-lable">D层负荷折线修正</div>
        <div class="top-row1">
          <div class="top-row1-col1 fl">序号</div>
          <div class="top-row1-col2 fl">DMSPJ_IN</div>
          <div class="top-row1-col3 fl">DMSPJ_AV</div>
        </div>
        <div class="top-row2">
          <div class="top-row1-col1 fl">1</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.X__ll__1__rr__,
                    'DMSPJ_IN1',
                     'X__ll__1__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.X__ll__1__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.Y__ll__1__rr__,
                    'DMSPJ_AV1',
                     'Y__ll__1__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.Y__ll__1__rr__ }}</div>
        </div>
        <div class="top-row2">
          <div class="top-row1-col1 fl">2</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.X__ll__2__rr__,
                    'DMSPJ_IN2',
                     'X__ll__2__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.X__ll__2__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.Y__ll__2__rr__,
                    'DMSPJ_AV2',
                     'Y__ll__2__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.Y__ll__2__rr__ }}</div>
        </div>
                <div class="top-row2">
          <div class="top-row1-col1 fl">3</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.X__ll__3__rr__,
                    'DMSPJ_IN3',
                     'X__ll__3__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.X__ll__3__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.Y__ll__3__rr__,
                    'DMSPJ_AV3',
                     'Y__ll__3__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.Y__ll__3__rr__ }}</div>
        </div>
                <div class="top-row2">
          <div class="top-row1-col1 fl">4</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.X__ll__4__rr__,
                    'DMSPJ_IN4',
                     'X__ll__4__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.X__ll__4__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.Y__ll__4__rr__,
                    'DMSPJ_AV4',
                     'Y__ll__4__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.Y__ll__4__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">5</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.X__ll__5__rr__,
                    'DMSPJ_IN5',
                     'X__ll__5__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.X__ll__5__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.Y__ll__5__rr__,
                    'DMSPJ_AV5',
                     'Y__ll__5__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.Y__ll__5__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">6</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.X__ll__6__rr__,
                    'DMSPJ_IN6',
                     'X__ll__6__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.X__ll__6__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.Y__ll__6__rr__,
                    'DMSPJ_AV6',
                     'Y__ll__6__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.Y__ll__6__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">7</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.X__ll__7__rr__,
                    'DMSPJ_IN7',
                     'X__ll__7__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.X__ll__7__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.Y__ll__7__rr__,
                    'DMSPJ_AV7',
                     'Y__ll__7__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.Y__ll__7__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">8</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.X__ll__8__rr__,
                    'DMSPJ_IN8',
                     'X__ll__8__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.X__ll__8__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.Y__ll__8__rr__,
                    'DMSPJ_AV8',
                     'Y__ll__8__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.Y__ll__8__rr__ }}</div>
        </div>
                                <div class="top-row2">
          <div class="top-row1-col1 fl">9</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.X__ll__9__rr__,
                    'DMSPJ_IN9',
                     'X__ll__9__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.X__ll__9__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.Y__ll__9__rr__,
                    'DMSPJ_AV9',
                     'Y__ll__9__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.Y__ll__9__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">10</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.X__ll__10__rr__,
                    'DMSPJ_IN10',
                     'X__ll__10__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.X__ll__10__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.Y__ll__10__rr__,
                    'DMSPJ_AV10',
                     'Y__ll__10__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.Y__ll__10__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">11</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.X__ll__11__rr__,
                    'DMSPJ_IN11',
                     'X__ll__11__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.X__ll__11__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFDK.Y__ll__11__rr__,
                    'DMSPJ_AV11',
                     'Y__ll__11__rr__',
                    'MKG__p__ECFDK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFDK.Y__ll__11__rr__ }}</div>
        </div>

        <div class="top-row3">
          <div class="fl top-row3-col1">IN=</div>
          <div class="fl"
          @click="toCompon(2, 'ZQF_SP', 'IOG', 'ZQF_SP_BM','主汽流量设定值')"   
           @dblclick="Cclick(infoList.IOG.ZQF_SP,'ZQF_SP', 'IOG', 'ZQF_SP_BM')">{{infoList.IOG.ZQF_SP}}
          </div>
          <div class="fl top-row3-col1">AV=</div>
          <div class="fl"
            @click="toCompon(2, 'AV', 'MKG__p__ECFDK', 'ECFDK_AV_BM','主汽流量折线修正系数输出')"   
           @dblclick="Cclick(infoList.MKG__p__ECFDK.AV,'AV', 'IOG', 'ECFDK_AV_BM')">{{infoList.MKG__p__ECFDK.AV}}</div>
        </div>
      </div>
      <div class="margin-top-row fl">
        <div class="main-lable">C层负荷折线修正</div>
        <div class="top-row1">
          <div class="top-row1-col1 fl">序号</div>
          <div class="top-row1-col2 fl">CMSPJ_IN</div>
          <div class="top-row1-col3 fl">CMSPJ_AV</div>
        </div>
        <div class="top-row2">
          <div class="top-row1-col1 fl">1</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.X__ll__1__rr__,
                    'CMSPJ_IN1',
                     'X__ll__1__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.X__ll__1__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.Y__ll__1__rr__,
                    'CMSPJ_AV1',
                     'Y__ll__1__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.Y__ll__1__rr__ }}</div>
        </div>
        <div class="top-row2">
          <div class="top-row1-col1 fl">2</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.X__ll__2__rr__,
                    'CMSPJ_IN2',
                     'X__ll__2__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.X__ll__2__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.Y__ll__2__rr__,
                    'CMSPJ_AV2',
                     'Y__ll__2__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.Y__ll__2__rr__ }}</div>
        </div>
                <div class="top-row2">
          <div class="top-row1-col1 fl">3</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.X__ll__3__rr__,
                    'CMSPJ_IN3',
                     'X__ll__3__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.X__ll__3__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.Y__ll__3__rr__,
                    'CMSPJ_AV3',
                     'Y__ll__3__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.Y__ll__3__rr__ }}</div>
        </div>
                <div class="top-row2">
          <div class="top-row1-col1 fl">4</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.X__ll__4__rr__,
                    'CMSPJ_IN4',
                     'X__ll__4__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.X__ll__4__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.Y__ll__4__rr__,
                    'CMSPJ_AV4',
                     'Y__ll__4__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.Y__ll__4__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">5</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.X__ll__5__rr__,
                    'CMSPJ_IN5',
                     'X__ll__5__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.X__ll__5__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.Y__ll__5__rr__,
                    'CMSPJ_AV5',
                     'Y__ll__5__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.Y__ll__5__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">6</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.X__ll__6__rr__,
                    'CMSPJ_IN6',
                     'X__ll__6__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.X__ll__6__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.Y__ll__6__rr__,
                    'CMSPJ_AV6',
                     'Y__ll__6__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.Y__ll__6__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">7</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.X__ll__7__rr__,
                    'CMSPJ_IN7',
                     'X__ll__7__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.X__ll__7__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.Y__ll__7__rr__,
                    'CMSPJ_AV7',
                     'Y__ll__7__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.Y__ll__7__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">8</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.X__ll__8__rr__,
                    'CMSPJ_IN8',
                     'X__ll__8__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.X__ll__8__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.Y__ll__8__rr__,
                    'CMSPJ_AV8',
                     'Y__ll__8__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.Y__ll__8__rr__ }}</div>
        </div>
                                <div class="top-row2">
          <div class="top-row1-col1 fl">9</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.X__ll__9__rr__,
                    'CMSPJ_IN9',
                     'X__ll__9__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.X__ll__9__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.Y__ll__9__rr__,
                    'CMSPJ_AV9',
                     'Y__ll__9__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.Y__ll__9__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">10</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.X__ll__10__rr__,
                    'CMSPJ_IN10',
                     'X__ll__10__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.X__ll__10__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.Y__ll__10__rr__,
                    'CMSPJ_AV10',
                     'Y__ll__10__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.Y__ll__10__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">11</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.X__ll__11__rr__,
                    'CMSPJ_IN11',
                     'X__ll__11__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.X__ll__11__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFCK.Y__ll__11__rr__,
                    'CMSPJ_AV11',
                     'Y__ll__11__rr__',
                    'MKG__p__ECFCK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFCK.Y__ll__11__rr__ }}</div>
        </div>

 <div class="top-row3">
          <div class="fl top-row3-col1">IN=</div>
          <div class="fl"
          @click="toCompon(2, 'ZQF_SP', 'IOG', 'ZQF_SP_BM','主汽流量设定值')"   
           @dblclick="Cclick(infoList.IOG.ZQF_SP,'ZQF_SP', 'IOG', 'ZQF_SP_BM')">{{infoList.IOG.ZQF_SP}}
          </div>
          <div class="fl top-row3-col1">AV=</div>
          <div class="fl"
            @click="toCompon(2, 'AV', 'MKG__p__ECFCK', 'ECFCK_AV_BM','主汽流量折线修正系数输出')"   
           @dblclick="Cclick(infoList.MKG__p__ECFCK.AV,'AV', 'IOG', 'ECFCK_AV_BM')">{{infoList.MKG__p__ECFCK.AV}}</div>
        </div>
      </div>
            <div class="margin-top-row fl">
        <div class="main-lable">B层负荷折线修正</div>
        <div class="top-row1">
          <div class="top-row1-col1 fl">序号</div>
          <div class="top-row1-col2 fl">BMSPJ_IN</div>
          <div class="top-row1-col3 fl">BMSPJ_AV</div>
        </div>
        <div class="top-row2">
          <div class="top-row1-col1 fl">1</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.X__ll__1__rr__,
                    'EMSPJ_IN1',
                     'X__ll__1__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.X__ll__1__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.Y__ll__1__rr__,
                    'EMSPJ_AV1',
                     'Y__ll__1__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.Y__ll__1__rr__ }}</div>
        </div>
        <div class="top-row2">
          <div class="top-row1-col1 fl">2</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.X__ll__2__rr__,
                    'EMSPJ_IN2',
                     'X__ll__2__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.X__ll__2__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.Y__ll__2__rr__,
                    'EMSPJ_AV2',
                     'Y__ll__2__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.Y__ll__2__rr__ }}</div>
        </div>
                <div class="top-row2">
          <div class="top-row1-col1 fl">3</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.X__ll__3__rr__,
                    'EMSPJ_IN3',
                     'X__ll__3__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.X__ll__3__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.Y__ll__3__rr__,
                    'EMSPJ_AV3',
                     'Y__ll__3__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.Y__ll__3__rr__ }}</div>
        </div>
                <div class="top-row2">
          <div class="top-row1-col1 fl">4</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.X__ll__4__rr__,
                    'EMSPJ_IN4',
                     'X__ll__4__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.X__ll__4__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.Y__ll__4__rr__,
                    'EMSPJ_AV4',
                     'Y__ll__4__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.Y__ll__4__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">5</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.X__ll__5__rr__,
                    'BMSPJ_IN5',
                     'X__ll__5__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.X__ll__5__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.Y__ll__5__rr__,
                    'BMSPJ_AV5',
                     'Y__ll__5__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.Y__ll__5__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">6</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.X__ll__6__rr__,
                    'BMSPJ_IN6',
                     'X__ll__6__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.X__ll__6__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.Y__ll__6__rr__,
                    'BMSPJ_AV6',
                     'Y__ll__6__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.Y__ll__6__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">7</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.X__ll__7__rr__,
                    'BMSPJ_IN7',
                     'X__ll__7__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.X__ll__7__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.Y__ll__7__rr__,
                    'BMSPJ_AV7',
                     'Y__ll__7__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.Y__ll__7__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">8</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.X__ll__8__rr__,
                    'BMSPJ_IN8',
                     'X__ll__8__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.X__ll__8__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.Y__ll__8__rr__,
                    'BMSPJ_AV8',
                     'Y__ll__8__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.Y__ll__8__rr__ }}</div>
        </div>
                                <div class="top-row2">
          <div class="top-row1-col1 fl">9</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.X__ll__9__rr__,
                    'BMSPJ_IN9',
                     'X__ll__9__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.X__ll__9__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.Y__ll__9__rr__,
                    'BMSPJ_AV9',
                     'Y__ll__9__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.Y__ll__9__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">10</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.X__ll__10__rr__,
                    'BMSPJ_IN10',
                     'X__ll__10__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.X__ll__10__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.Y__ll__10__rr__,
                    'BMSPJ_AV10',
                     'Y__ll__10__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.Y__ll__10__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">11</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.X__ll__11__rr__,
                    'BMSPJ_IN11',
                     'X__ll__11__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.X__ll__11__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFBK.Y__ll__11__rr__,
                    'BMSPJ_AV11',
                     'Y__ll__11__rr__',
                    'MKG__p__ECFBK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFBK.Y__ll__11__rr__ }}</div>
        </div>

 <div class="top-row3">
          <div class="fl top-row3-col1">IN=</div>
          <div class="fl"
          @click="toCompon(2, 'ZQF_SP', 'IOG', 'ZQF_SP_BM','主汽流量设定值')"   
           @dblclick="Cclick(infoList.IOG.ZQF_SP,'ZQF_SP', 'IOG', 'ZQF_SP_BM')">{{infoList.IOG.ZQF_SP}}
          </div>
          <div class="fl top-row3-col1">AV=</div>
          <div class="fl"
            @click="toCompon(2, 'AV', 'MKG__p__ECFBK', 'ECFBK_AV_BM','主汽流量折线修正系数输出')"   
           @dblclick="Cclick(infoList.MKG__p__ECFBK.AV,'AV', 'IOG', 'ECFBK_AV_BM')">{{infoList.MKG__p__ECFBK.AV}}</div>
        </div>
      </div>
            <div class="margin-top-row fl">
        <div class="main-lable">A层负荷折线修正</div>
        <div class="top-row1">
          <div class="top-row1-col1 fl">序号</div>
          <div class="top-row1-col2 fl">AMSPJ_IN</div>
          <div class="top-row1-col3 fl">AMSPJ_AV</div>
        </div>
        <div class="top-row2">
          <div class="top-row1-col1 fl">1</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.X__ll__1__rr__,
                    'AMSPJ_IN1',
                     'X__ll__1__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.X__ll__1__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.Y__ll__1__rr__,
                    'AMSPJ_AV1',
                     'Y__ll__1__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.Y__ll__1__rr__ }}</div>
        </div>
        <div class="top-row2">
          <div class="top-row1-col1 fl">2</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.X__ll__2__rr__,
                    'AMSPJ_IN2',
                     'X__ll__2__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.X__ll__2__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.Y__ll__2__rr__,
                    'AMSPJ_AV2',
                     'Y__ll__2__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.Y__ll__2__rr__ }}</div>
        </div>
                <div class="top-row2">
          <div class="top-row1-col1 fl">3</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.X__ll__3__rr__,
                    'AMSPJ_IN3',
                     'X__ll__3__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.X__ll__3__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.Y__ll__3__rr__,
                    'AMSPJ_AV3',
                     'Y__ll__3__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.Y__ll__3__rr__ }}</div>
        </div>
                <div class="top-row2">
          <div class="top-row1-col1 fl">4</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.X__ll__4__rr__,
                    'AMSPJ_IN4',
                     'X__ll__4__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.X__ll__4__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.Y__ll__4__rr__,
                    'AMSPJ_AV4',
                     'Y__ll__4__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.Y__ll__4__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">5</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.X__ll__5__rr__,
                    'AMSPJ_IN5',
                     'X__ll__5__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.X__ll__5__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.Y__ll__5__rr__,
                    'AMSPJ_AV5',
                     'Y__ll__5__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.Y__ll__5__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">6</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.X__ll__6__rr__,
                    'AMSPJ_IN6',
                     'X__ll__6__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.X__ll__6__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.Y__ll__6__rr__,
                    'AMSPJ_AV6',
                     'Y__ll__6__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.Y__ll__6__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">7</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.X__ll__7__rr__,
                    'AMSPJ_IN7',
                     'X__ll__7__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.X__ll__7__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.Y__ll__7__rr__,
                    'AMSPJ_AV7',
                     'Y__ll__7__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.Y__ll__7__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">8</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.X__ll__8__rr__,
                    'AMSPJ_IN8',
                     'X__ll__8__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.X__ll__8__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.Y__ll__8__rr__,
                    'AMSPJ_AV8',
                     'Y__ll__8__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.Y__ll__8__rr__ }}</div>
        </div>
                                <div class="top-row2">
          <div class="top-row1-col1 fl">9</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.X__ll__9__rr__,
                    'AMSPJ_IN9',
                     'X__ll__9__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.X__ll__9__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.Y__ll__9__rr__,
                    'AMSPJ_AV9',
                     'Y__ll__9__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.Y__ll__9__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">10</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.X__ll__10__rr__,
                    'AMSPJ_IN10',
                     'X__ll__10__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.X__ll__10__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.Y__ll__10__rr__,
                    'AMSPJ_AV10',
                     'Y__ll__10__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.Y__ll__10__rr__ }}</div>
        </div>
                        <div class="top-row2">
          <div class="top-row1-col1 fl">11</div>
          <div class="top-row1-col2 colorl fl"
          @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.X__ll__11__rr__,
                    'AMSPJ_IN11',
                     'X__ll__11__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.X__ll__11__rr__ }}</div>
          <div class="top-row1-col3 colorl fl"
           @click="
                  toIpt(
                    infoList.MKG__p__ECFAK.Y__ll__11__rr__,
                    'AMSPJ_AV11',
                     'Y__ll__11__rr__',
                    'MKG__p__ECFAK',
                   
                  )
                "
        >
          {{ infoList.MKG__p__ECFAK.Y__ll__11__rr__ }}</div>
        </div>

 <div class="top-row3">
          <div class="fl top-row3-col1">IN=</div>
          <div class="fl"
          @click="toCompon(2, 'ZQF_SP', 'IOG', 'ZQF_SP_BM','主汽流量设定值')"   
           @dblclick="Cclick(infoList.IOG.ZQF_SP,'ZQF_SP', 'IOG', 'ZQF_SP_BM')">{{infoList.IOG.ZQF_SP}}
          </div>
          <div class="fl top-row3-col1">AV=</div>
          <div class="fl"
            @click="toCompon(2, 'AV', 'MKG__p__ECFAK', 'ECFAK_AV_BM','主汽流量折线修正系数输出')"   
           @dblclick="Cclick(infoList.MKG__p__ECFAK.AV,'AV', 'IOG', 'ECFAK_AV_BM')">{{infoList.MKG__p__ECFAK.AV}}</div>
        </div>
      </div>
      <!-- 热损失图 -->
      <div class="margin-top-row3 fl">
        <!-- <div id="rts_Chart" :option="option"></div> -->
      </div>
    </div>
    <!-- 主体部分的下半部分 -->
    <div class="margin-bottom">
      <div class="bottom-row1 fl">E 层折线趋势图</div>
      <div class="bottom-row2 fl">
        <div class="bottom-row2-col">
          <div class="bottom-row2-col1 fl">类别</div>
          <div class="bottom-row2-col2 fl">开度基值</div>
          <div class="bottom-row2-col2 fl">煤量</div>
          <div class="bottom-row2-col3 fl">控制</div>
          <div class="bottom-row2-col2 fl">指令</div>
          <div class="bottom-row2-col2 fl">反馈</div>
          <div class="bottom-row2-col2 fl">下限</div>
          <div class="bottom-row2-col2 fl">上限</div>

        </div>
         <div class="bottom-row3-col">
          <div class="bottom-row2-col1 fl">E层层操</div>
          <div class="bottom-row2-col2 fl"
            @click="
                  toIpt(
                    infoList.MKG.ECF_MVE_B2,
                    'E层层操开度基值',
                     'ECF_MVE_B2',
                    'MKG',
                   
                  )
                ">{{infoList.MKG.ECF_MVE_B2}}% 
          </div>
          <div class="bottom-row2-col2 fl" @click="toCompon(2, 'GML_E_PV', 'IOG', 'GML_E_PV_BM','5#给煤量过程值')"   
           @dblclick="Cclick(infoList.IOG.GML_E_PV,'GML_E_PV', 'IOG', 'GML_E_PV_BM')">{{infoList.IOG.GML_E_PV}}t/h</div>
          <div class="bottom-row2-col3 fl">
            <div class="bottonx fl"
                :style="{
                        background:
                        infoList.RQM__p__ECF_MAN4.RM==1  || infoList.RQM__p__ECF_MAN5.RM==1 ? '#2AFC30' : 'red'
                        }"
                        @click="toCompon(0,'MFL_MANECFS',2)">A</div>
            <!-- <div class="bottonx fl">Q</div> -->
            </div>
          <div class="bottom-row2-col2 fl"
           @click="toCompon(2, 'ECF_E_MV', 'IOG', 'ECF_E_MV_BM','E层风XK输出')"   
           @dblclick="Cclick(infoList.IOG.ECF_E_MV,'ECF_E_MV', 'IOG', 'ECF_E_MV_BM')">{{infoList.IOG.ECF_E_MV}}%</div>
          <div class="bottom-row2-col2 fl"
           @click="toCompon(2, 'ECF_E_FV', 'IOG', 'ECF_E_FV_BM','E层风阀门反馈')"   
           @dblclick="Cclick(infoList.IOG.ECF_E_FV,'ECF_E_FV', 'IOG', 'ECF_E_FV_BM')">{{infoList.IOG.ECF_E_FV}}%</div>
          <div class="bottom-row2-col2 fl colorl"
           @click="
                  toIpt(
                    infoList.RQM__p__ECF_MAN5.OutB,
                    'OutB',
                     'OutB',
                    'RQM__p__ECF_MAN5',
                   
                  )
                "
        >
          {{ infoList.RQM__p__ECF_MAN5.OutB }}%</div>
          <div class="bottom-row2-col2 fl colorl"
            @click="
                  toIpt(
                    infoList.RQM__p__ECF_MAN5.OutT,
                    'OutT',
                     'OutT',
                    'RQM__p__ECF_MAN5',
                   
                  )
                "
        >
          {{ infoList.RQM__p__ECF_MAN5.OutT }}%</div>
          <!-- <div class="bottom-row2-col4 fl">切除校正</div> -->
          
        </div>
         <div class="bottom-row3-col">
          <div class="bottom-row2-col1 fl">D层层操</div>
          <div class="bottom-row2-col2 fl"
            @click="
                  toIpt(
                    infoList.MKG.ECF_MVD_B2,
                    'D层层操开度基值',
                     'ECF_MVD_B2',
                    'MKG',
                   
                  )
                ">{{infoList.MKG.ECF_MVD_B2}}%
          </div>
          <div class="bottom-row2-col2 fl" @click="toCompon(2, 'GML_D_PV', 'IOG', 'GML_D_PV_BM','4#给煤量过程值')"   
           @dblclick="Cclick(infoList.IOG.GML_D_PV,'GML_D_PV', 'IOG', 'GML_D_PV_BM')">{{infoList.IOG.GML_D_PV}}t/h</div>
          <div class="bottom-row2-col3 fl">
            <div class="bottonx fl"
            :style="{
                        background:
                        infoList.RQM__p__ECF_MAN4.RM==1  || infoList.RQM__p__ECF_MAN5.RM==1 ? '#2AFC30' : 'red'
                        }"
                        @click="toCompon(0,'MFL_MANECFS',2)">A</div>
            <!-- <div class="bottonx fl">Q</div> -->
            </div>
          <div class="bottom-row2-col2 fl"
           @click="toCompon(2, 'ECF_D_MV', 'IOG', 'ECF_D_MV_BM','D层风XK输出')"   
           @dblclick="Cclick(infoList.IOG.ECF_D_MV,'ECF_D_MV', 'IOG', 'ECF_D_MV_BM')">{{infoList.IOG.ECF_D_MV}}%</div>
          <div class="bottom-row2-col2 fl"
           @click="toCompon(2, 'ECF_D_FV', 'IOG', 'ECF_D_FV_BM','D层风阀门反馈')"   
           @dblclick="Cclick(infoList.IOG.ECF_D_FV,'ECF_D_FV', 'IOG', 'ECF_D_FV_BM')">{{infoList.IOG.ECF_D_FV}}%</div>
          <div class="bottom-row2-col2 fl colorl"
           @click="
                  toIpt(
                    infoList.RQM__p__ECF_MAN4.OutB,
                    'OutB',
                     'OutB',
                    'RQM__p__ECF_MAN4',
                   
                  )
                "
        >
          {{ infoList.RQM__p__ECF_MAN4.OutB }}%</div>
          <div class="bottom-row2-col2 fl colorl"
            @click="
                  toIpt(
                    infoList.RQM__p__ECF_MAN4.OutT,
                    'OutT',
                     'OutT',
                    'RQM__p__ECF_MAN4',
                   
                  )
                "
        >
          {{ infoList.RQM__p__ECF_MAN4.OutT }}%</div>
          <!-- <div class="bottom-row2-col4 fl">切除校正</div> -->
          
        </div>
                 <div class="bottom-row3-col">
          <div class="bottom-row2-col1 fl">C层层操</div>
          <div class="bottom-row2-col2 fl"
         @click="
                  toIpt(
                    infoList.MKG.ECF_MVC_B2,
                    'C层层操开度基值',
                     'ECF_MVC_B2',
                    'MKG',
                   
                  )
                ">{{infoList.MKG.ECF_MVC_B2}}%
          </div>
          <div class="bottom-row2-col2 fl" @click="toCompon(2, 'GML_C_PV', 'IOG', 'GML_C_PV_BM','3#给煤量过程值')"   
           @dblclick="Cclick(infoList.IOG.GML_C_PV,'GML_C_PV', 'IOG', 'GML_C_PV_BM')">{{infoList.IOG.GML_C_PV}}t/h</div>
          <div class="bottom-row2-col3 fl">
            <div class="bottonx fl"
             :style="{
                        background:
                        infoList.RQM__p__ECF_MAN1.RM== 1 || infoList.RQM__p__ECF_MAN2.RM== 1 || infoList.RQM__p__ECF_MAN3.RM == 1 ? '#2AFC30' : 'red'
                        }"
                        @click="toCompon(0,'MFL_MANECFX',3)">A</div>
            <!-- <div class="bottonx fl">Q</div> -->
            </div>
          <div class="bottom-row2-col2 fl"
           @click="toCompon(2, 'ECF_C_MV', 'IOG', 'ECF_C_MV_BM','C层风XK输出')"   
           @dblclick="Cclick(infoList.IOG.ECF_C_MV,'ECF_C_MV', 'IOG', 'ECF_C_MV_BM')">{{infoList.IOG.ECF_C_MV}}%</div>
          <div class="bottom-row2-col2 fl"
           @click="toCompon(2, 'ECF_C_FV', 'IOG', 'ECF_C_FV_BM','C层风阀门反馈')"   
           @dblclick="Cclick(infoList.IOG.ECF_C_FV,'ECF_C_FV', 'IOG', 'ECF_C_FV_BM')">{{infoList.IOG.ECF_C_FV}}%</div>
          <div class="bottom-row2-col2 fl colorl"
           @click="
                  toIpt(
                    infoList.RQM__p__ECF_MAN3.OutB,
                    'OutB',
                     'OutB',
                    'RQM__p__ECF_MAN3',
                   
                  )
                "
        >
          {{ infoList.RQM__p__ECF_MAN3.OutB }}%</div>
          <div class="bottom-row2-col2 fl colorl"
            @click="
                  toIpt(
                    infoList.RQM__p__ECF_MAN3.OutT,
                    'OutT',
                     'OutT',
                    'RQM__p__ECF_MAN3',
                   
                  )
                "
        >
          {{ infoList.RQM__p__ECF_MAN3.OutT }}%</div>
          <!-- <div class="bottom-row2-col4 fl">切除校正</div> -->
          
        </div>
                 <div class="bottom-row3-col">
          <div class="bottom-row2-col1 fl">B层层操</div>
          <div class="bottom-row2-col2 fl"
           @click="
                  toIpt(
                    infoList.MKG.ECF_MVB_B2,
                    'B层层操开度基值',
                     'ECF_MVB_B2',
                    'MKG',
                   
                  )
                ">{{infoList.MKG.ECF_MVB_B2}}%
          </div>
          <div class="bottom-row2-col2 fl" @click="toCompon(2, 'GML_B_PV', 'IOG', 'GML_B_PV_BM','2#给煤量过程值')"   
           @dblclick="Cclick(infoList.IOG.GML_B_PV,'GML_B_PV', 'IOG', 'GML_B_PV_BM')">{{infoList.IOG.GML_B_PV}}t/h</div>
          <div class="bottom-row2-col3 fl">
            <div class="bottonx fl"
            :style="{
                        background:
                        infoList.RQM__p__ECF_MAN1.RM== 1 || infoList.RQM__p__ECF_MAN2.RM== 1 || infoList.RQM__p__ECF_MAN3.RM == 1 ? '#2AFC30' : 'red'
                        }"
                        @click="toCompon(0,'MFL_MANECFX',3)">A</div>
            <!-- <div class="bottonx fl">Q</div> -->
            </div>
          <div class="bottom-row2-col2 fl"
           @click="toCompon(2, 'ECF_B_MV', 'IOG', 'ECF_B_MV_BM','B层风XK输出')"   
           @dblclick="Cclick(infoList.IOG.ECF_B_MV,'ECF_B_MV', 'IOG', 'ECF_B_MV_BM')">{{infoList.IOG.ECF_B_MV}}%</div>
          <div class="bottom-row2-col2 fl"
           @click="toCompon(2, 'ECF_B_FV', 'IOG', 'ECF_B_FV_BM','B层风阀门反馈')"   
           @dblclick="Cclick(infoList.IOG.ECF_B_FV,'ECF_B_FV', 'IOG', 'ECF_B_FV_BM')">{{infoList.IOG.ECF_B_FV}}%</div>
          <div class="bottom-row2-col2 fl colorl"
           @click="
                  toIpt(
                    infoList.RQM__p__ECF_MAN2.OutB,
                    'OutB',
                     'OutB',
                    'RQM__p__ECF_MAN2',
                   
                  )
                "
        >
          {{ infoList.RQM__p__ECF_MAN2.OutB }}%</div>
          <div class="bottom-row2-col2 fl colorl"
            @click="
                  toIpt(
                    infoList.RQM__p__ECF_MAN2.OutT,
                    'OutT',
                     'OutT',
                    'RQM__p__ECF_MAN2',
                   
                  )
                "
        >
          {{ infoList.RQM__p__ECF_MAN2.OutT }}%</div>
          <!-- <div class="bottom-row2-col4 fl">切除校正</div> -->
          
        </div>
                 <div class="bottom-row3-col">
          <div class="bottom-row2-col1 fl">A层层操</div>
          <div class="bottom-row2-col2 fl"
          @click="
                  toIpt(
                    infoList.MKG.ECF_MVA_B2,
                    'A层层操开度基值',
                     'ECF_MVA_B2',
                    'MKG',
                   
                  )
                ">{{infoList.MKG.ECF_MVA_B2}}%
          </div>
          <div class="bottom-row2-col2 fl" @click="toCompon(2, 'GML_A_PV', 'IOG', 'GML_A_PV_BM','1#给煤量过程值')"   
           @dblclick="Cclick(infoList.IOG.GML_A_PV,'GML_A_PV', 'IOG', 'GML_A_PV_BM')">{{infoList.IOG.GML_A_PV}}t/h</div>
          <div class="bottom-row2-col3 fl">
            <div class="bottonx fl"
           :style="{
                        background:
                        infoList.RQM__p__ECF_MAN1.RM== 1 || infoList.RQM__p__ECF_MAN2.RM== 1 || infoList.RQM__p__ECF_MAN3.RM == 1 ? '#2AFC30' : 'red'
                        }"
                        @click="toCompon(0,'MFL_MANECFX',3)">A</div>
            <!-- <div class="bottonx fl">Q</div> -->
            </div>
          <div class="bottom-row2-col2 fl"
           @click="toCompon(2, 'ECF_A_MV', 'IOG', 'ECF_A_MV_BM','A层风XK输出')"   
           @dblclick="Cclick(infoList.IOG.ECF_A_MV,'ECF_A_MV', 'IOG', 'ECF_A_MV_BM')">{{infoList.IOG.ECF_A_MV}}%</div>
          <div class="bottom-row2-col2 fl"
           @click="toCompon(2, 'ECF_A_FV', 'IOG', 'ECF_A_FV_BM','A层风阀门反馈')"   
           @dblclick="Cclick(infoList.IOG.ECF_A_FV,'ECF_A_FV', 'IOG', 'ECF_A_FV_BM')">{{infoList.IOG.ECF_A_FV}}%</div>
          <div class="bottom-row2-col2 fl colorl"
           @click="
                  toIpt(
                    infoList.RQM__p__ECF_MAN1.OutB,
                    'OutB',
                     'OutB',
                    'RQM__p__ECF_MAN1',
                   
                  )
                "
        >
          {{ infoList.RQM__p__ECF_MAN1.OutB }}%</div>
          <div class="bottom-row2-col2 fl colorl"
            @click="
                  toIpt(
                    infoList.RQM__p__ECF_MAN1.OutT,
                    'OutT',
                     'OutT',
                    'RQM__p__ECF_MAN1',
                   
                  )
                "
        >
          {{ infoList.RQM__p__ECF_MAN1.OutT }}%</div>
          <!-- <div class="bottom-row2-col4 fl">切除校正</div> -->
          
        </div>
      </div>
        <div class="bottom-row3 fl">效率及热损失趋势图</div>
    </div>

     <Historical
      v-if="isHshow"
      @sendStatus="isClose1"
      :historyname="historyname"
      :node="node"
      :Lkname="Lkname"
      :infoList="infoList"
    ></Historical>
    <inputVal ref="inputVal"></inputVal>
    <JRLcysz
      v-if="flag == 4 ? (isComShow = true) : (isComShow = false)"
      @sendStatus="isClose"
      :infoList="infoList"
    ></JRLcysz>
 <mflmanyManual
      v-if="mflmanyManual"
	  :titname="Manualtitname"
	  :numM="Manualnode"
    :mark="Manualmark"
    :manvar="Manualvar"
      @sendStatus="isClose"
      :infoList="infoList"
    ></mflmanyManual>

  </div>
</template>
<script>
import Historical from "@/components/Historical";
import inputVal from "@/components/inputVal"; //输入框组件
import JRLcysz from "@/components/JRL_cysz.vue"; //常用设置组件
import mflmanyManual from "@/components/MflmanyManual.vue"; //多手操器
export default {
  name: "index",
  components: {
    Historical,
    inputVal,
    JRLcysz,
      mflmanyManual,
  },
  props: {
    infoList: {
      type: Object,
      default: () => {
        return {}; // 默认值
      },
    },
  },
  data: () => {
    return {
      option:'',
        mflmanyManual:false,
      Manualmark:'',
      Manualvar:'',
      headerStyle: {
        "font-weight": "500",
        "font-size": "1.8vh",
        height: "1vh",
        borderColor: "#0f3747",
        background: "#205365",
        padding: "0px",
        border: "none",
        color: "#5ca4a6",
      },
      flag: 0,
      isMshow:false,
      Manualname: "",
      Manualnode: "",
      ManualAname: "",
      Manualtitname: "",
      isComShow: false,
      historyname: "",
      nodename: "",
      Observername: "",
      Observernode: "",
      Rsfname: "",
      Rsfnode: "",
      bodyHeight: "",
      Firstcontrolname: "",
      Firstcontrolnode: "",
      Firstcontroltitname: "",
      fullscreenLoading: true,
      isHshow: false,
      isOshow: false,
      isRshow: false,
      screenWidth: document.body.clientWidth,
      screeHeight: "",
      conHeight: "",
      leftHeight: "",
      leftcon: "",
      tabline: "",
      tabline1: "",
      tabstyle: "",
      tablist: "",
      tabdatawidth: "",
      tabdatawidth1: "",
      Aname: "",
      spotArr: [],
      authInfo: [],
      changValue: null,
      config: {
        data: [10],
        shape: "round",
        colors: ["#43ff44", "#43ff44"],
        waveOpacity: "1",
        waveNum: "50",
        formatter: "{value}",
      },
      rightHeight: "",
      myChart:''
    };
  },

  watch: {
     option: {
     
      handler(newVal, oldVal) {
        if (this.myChart) {
          if (newVal) {
            this.myChart.setOption(newVal, true);
          } else {
            this.myChart.setOption(oldVal);
          }
        } else {
          this.drawLine();
        }
      },
    },
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
  },
  created() {
                this.option = {
        xAxis: {
          type: 'category',
          data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
        },
        yAxis: {
          type: 'value'
        },
        series: [
          {
            data: [
              120,
              {
                value: 200,
                itemStyle: {
                  color: '#a90000'
                }
              },
              150,
              80,
              70,
              110,
              130
            ],
            type: 'bar'
          }
        ]
      };
    this.projectData = JSON.parse(localStorage.getItem("deviceType"));
      this.spotArr = JSON.parse(localStorage.getItem("spotArr")) ? JSON.parse(localStorage.getItem("spotArr")) : []
     		this.authInfo = JSON.parse(localStorage.getItem("autharr"))
		this.UserInfo = JSON.parse(localStorage.getItem("UserInfo"))
    this.$bus.$on("sendMsg", (msg, msg1) => {
      // 兄弟组件发送来的消息
      this.flag = msg;
    });
  },

  computed: {},
  mounted() {},
  methods: {
    // 图表
    drawLine() {
        // 基于准备好的dom，初始化echarts实例
        let myChart = this.$echarts.init(
          document.getElementById("rts_Chart"),
          "dark"
        );
        this.myChart = myChart;
        // 绘制图表
        this.myChart.setOption(this.option);
        window.addEventListener("resize", function () {
          myChart.resize();
        });
        this.myChart.on('click',function(params){
        })
      },
    		// 双击事件
		Cclick(num,mark,node,name){
		// 组装数据
		 clearTimeout(this.grouptime);
		let spojobj = {
			num:num,
			mark:mark,
			node:node,
			name:name
		}
		this.spotArr.push(spojobj)
		// 数组去重
		let deWeightThree = () => {
			let map = new Map();
			for (let item of this.spotArr) {
				if (!map.has(item.name)) {
					this.$message.closeAll()
					map.set(item.name, item);
					this.$message.success('已添加');
				}else{
					this.$message.closeAll()
					this.$message.error('已添加到变量池里，切勿重复添加');
				}
			}
			return [...map.values()];
		}
		this.spotArr = deWeightThree();
		localStorage.setItem("spotArr", JSON.stringify(this.spotArr))
	},
    // 打开下置输入窗口
    toIpt(data, name, historyname, node, type) {
      if(!this.authInfo[1]){
				let str = this.authInfo[0]
				// 判断是否为调试还是查看权限
        console.log(str.charAt(str.length-1))
				if (str.charAt(str.length-1) == "r") {
					this.$message.error('暂无操作权限');
				}
			}else{
        this.$refs.inputVal.open(data, name, historyname, node, type);
      }
      
    },
    closeCompon() {
      this.$emit("sendStatus", "Param01", false);
    },
    // 双击事件
    Cclick(num, mark, node, name) {
      // 组装数据
      clearTimeout(this.grouptime);
      let spojobj = {
        num: num,
        mark: mark,
        node: node,
        name: name,
      };
      this.spotArr.push(spojobj);
      // 数组去重
      let deWeightThree = () => {
        let map = new Map();
        for (let item of this.spotArr) {
          if (!map.has(item.name)) {
            this.$message.closeAll();
            map.set(item.name, item);
            this.$message.success("已添加");
          } else {
            this.$message.closeAll();
            this.$message.error("已添加到变量池里，切勿重复添加");
          }
        }

        return [...map.values()];
      };
      this.spotArr = deWeightThree();
      localStorage.setItem("spotArr", JSON.stringify(this.spotArr));
    },
    isClose(val,val1) {
      // (this.flag = 3), this.$bus.$emit("footersendMsg", 3);
          switch(val){
        case 'Historical':
          return this.isHshow = val1
            case 'MfZQFOZX':
          return this.MfZQFOZX = val1
          case 'mflmanyManual':
					return this.mflmanyManual = val1
      }
    },
    isClose1() {
      this.isHshow = false;
    },
    toCompon(key, name, name2, name3, name4, titname) {
      this.isIndex = key;
      this.historyname = name;
      this.node = name2;
      this.Lkname = name3;
      switch (key) {
          case 0:
            if(!this.authInfo[1]){
				let str = this.authInfo[0]
				// 判断是否为调试还是查看权限
				if (str.charAt(str.length-1) == "r") {
					this.$message.error('暂无调试权限');
				}
			}else{
			  return this.mflmanyManual = true,this.Manualtitname = name,this.Manualnode= name2,this.Manualmark = name3,this.Manualvar = titname
      }
        case 1:
          return (
            (this.isFshow = true),
            (this.Firstcontrolname = name),
            (this.Firstcontrolnode = name2),
            (this.Firstcontroltitname = titname)
          );
        case 2:
          return (
		  (this.isHshow = true),
		  (this.chName = name4)
		  );
      }
    },
    // 打开历史趋势窗口
    toHistory(data) {
      this.$refs.history.open(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.mflswitch {
  width: 96vw;
  height: 100%;
  margin-left: 1.5vw;
  //  background: #e9f7ff;
  background-image: linear-gradient(#0e3449 60%, #0e3146 61%, #0c2a38 100%),
    linear-gradient(#0e3449, #0e3449);
  background-blend-mode: normal, normal;
  overflow: hidden;
  //  cursor:move;
  border-radius: 5px;
   #rts_Chart{
    width: 100%;
    height: 100%;
    }
  .ziti {
    font-size: 0.9vw;
  }
  .zhuangshi1 {
    width: 30vw;
    height: 10vh;
    background-color: #0ef7ff;
    opacity: 0.66;
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
  .title-hang {
    height: 5vh;
    width: 96vw;
    font-family: MicrosoftYaHei;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 5vh;
    letter-spacing: 0vh;
  }
  .conter1 {
    margin-left: 1vw;
    width: 17vw;
    height: 29vh;
    line-height: 29vh;
    background-image: url("~@/assets/images/rfl_evaluate01.png");
    background-size: 100% 100%;
  }

  .conter1 {
    margin-left: 1vw;
    width: 17vw;
    height: 29vh;
    line-height: 29vh;
    background-image: url("~@/assets/images/rfl_evaluate01.png");
    background-size: 100% 100%;
  }
  .conter2 {
    margin-left: 1vw;
    width: 29vw;
    height: 29vh;
    // line-height: 29vh;
    background-image: url("~@/assets/images/rfl_evaluate03.png");
    background-size: 100% 100%;
  }
  .title1 {
    width: 8vw;
    height: 2vh;
    font-family: PingFang-SC-Regular;
    font-size: 2vh;
    font-weight: normal;
    font-stretch: normal;
    line-height: 2vh;
    letter-spacing: 0vh;
    color: #ffffff;
    margin-top: 1vh;
    margin-left: 1vw;
  }
  .divcei {
    margin-top: 3vh;
    margin-left: 4vw;
    width: 9vw;
    height: 20vh;
    line-height: 20vh;
    background-image: url("~@/assets/images/rfl_evaluate02.png");
    background-size: 100% 100%;
    font-size: 1vw;
    font-weight: bold;
    font-stretch: normal;
    letter-spacing: 0vw;
    color: #ffffff;
    text-align: center;
  }

  .zhuangshizb {
    width: 14vw;
    height: 3vh;
    background-image: url("~@/assets/images/rfl_evaluate06.png");
    background-size: 100% 100%;
    margin-left: 3.5vw;
    margin-top: 1.6vh;
  }
  .zhuangshizj {
    width: 26vw;
    height: 5vh;
    line-height: 5vh;
    margin-top: 1vh;
    text-align: center;
    background-image: url("~@/assets/images/rfl_evaluate05.png");
    background-size: 100% 100%;
    margin-left: 5vw;
    font-family: MicrosoftYaHei;
    font-size: 3vh;
    font-weight: normal;
    font-stretch: normal;
    letter-spacing: 0vh;
    color: #0ef7ff;
  }
  .zhuangshiyb {
    width: 14vw;
    height: 3vh;
    background-image: url("~@/assets/images/rfl_evaluate04.png");
    background-size: 100% 100%;
    margin-left: 5vw;
    margin-top: 1.6vh;
  }
  .bottonx {
    width: 1vw;
    height: 2vh;
    text-align: center;
    line-height: 2vh;
    border: 2px solid rgb(217, 243, 145);
    font-size: 0.9vw;
    // margin-right: 0.1vw;
    color: #fff;
    margin-top:1.5vh;
    margin-right:0.1vw;
  } margin-right:0.1vw;
  .title-hang {
    width: 100%;
  }
  // .mainall{
  //   margin-top:0vh;
  // }
  .margin-top {
    height: 37vh;
    margin: 2vh 0 0 0;
    color: #2fc3e3;
    font-size: 0.9vw;
  }
  .margin-bottom {
    height: 37vh;
    margin: 2vh 0 0 0;
    color: #2fc3e3;
    font-size: 0.9vw;
    // border: 1px saddlebrown solid;
  }
  .margin-top-row {
    width: 15vw;
    //  border:1px solid saddlebrown;
    height: 36vh;
    margin-right: 1vw;
  }
  .margin-top-row3 {
    width: 15vw;
    //  border:1px solid saddlebrown;
    height: 36vh;
    margin-right: 1vw;
  }
  .top-row1 {
    border: #00e4ff20 1px solid;
    height: 3vh;
    line-height: 3vh;
  }
  .top-row2 {
    // border-bottom: #00e4ff20 1px solid;
    border-left: #00e4ff20 1px solid;
    border-right: #00e4ff20 1px solid;
    height: 2.3vh;
    line-height: 2.3vh;
  }
  .top-row3 {
    border: #00e4ff20 1px solid;
    height: 3vh;
    line-height: 3vh;
    text-align: center;
       cursor:pointer;
  }
  .colorl {
    color: #00ffb4;
  }
  .top-row1-col1 {
    width: 2vw;
    text-align: center;
    border-right: #00e4ff20 1px solid;
  }
  .top-row1-col2 {
    width: 6vw;
    text-align: center;
    border-right: #00e4ff20 1px solid;
       cursor:pointer;
  }
  .top-row1-col3 {
    width: 6vw;
    text-align: center;
       cursor:pointer;
  }
  .main-lable {
    width: 15vw;
    text-align: center;
    color: #fff;
  }
  .top-row3-col1 {
    width: 3vw;
    text-align: center;
    margin: 0 0 0 1vw;
       cursor:pointer;
  }
  .bottom-row1 {
    width: 27vw;
     height: 37vh;
    margin-right: 1vw;
    border:1px solid saddlebrown;
  }
  .bottom-row3 {
    width: 26vw;
    height: 37vh;
       margin-left: 1vw;
      border:1px solid saddlebrown;

  }
  .bottom-row2 {
    width: 40vw;
    height: 37vh;
    border-left: #00e4ff20 1px solid;
    border-right: #00e4ff20 1px solid;
    border-top: #00e4ff20 1px solid;

  }
  .bottom-row2-col {
    color: #fff;
    height:6vh;
    line-height: 6vh;
      border-bottom: #00e4ff20 1px solid;
  }
  .bottom-row3-col {
    height: 6vh;
    line-height: 6vh;
     border-bottom: #00e4ff20 1px solid;
  }
  .bottom-row2-col1 {
    width: 4vw;
    text-align: center;
  }
  .bottom-row2-col3 {
    width: 3vw;
    text-align: center;
  }
  .bottom-row2-col2 {
    width: 4.5vw;
    text-align: center;
       cursor:pointer;
  }
.bottom-row2-col4{
  	width: 4vw;
	height: 3vh;
  line-height: 3vh;
	background-color: #22fff7;
	border-radius: 0vw;
  text-align: center;
  margin-top:1vh;
  color:#000;
}
}
</style>